<template>
  <div class="tncode">
    <div
      class="body"
      @mousemove="mouseMove"
      @mouseup="mouseUp"
      @touchmove="mouseMove"
      @touchend="mouseUp"
    >
      <canvas class="bg" ref="bg"></canvas>
      <canvas class="mark" ref="mark"></canvas>
      <div class="success-error error" v-if="this.isError">验证失败</div>
      <div class="success-error success" v-if="this.isSuccess">验证成功</div>

      <div class="solid">
        <div
          class="botton"
          @mousedown="mouseDown"
          @touchstart="mouseDown"
          :style="{
            transform: `translate(${moveOffset}px,0px)`,
          }"
        ></div>
        <div class="channel" v-if="!isSolid">拖动左边滑块完成上方拼图</div>
      </div>
      <div class="tools">
        <div class="close" @click="close"></div>
        <div class="refrese" @click="loadImg"></div>
      </div>
    </div>
  </div>
</template>

<script>
var _this = null;
export default {
  //   props: {
  //     loadurl: String,
  //     posturl: String,
  //     postData: Object,
  //   },
  data() {
    return {
      handleCall: function () {},
      loadurl: "",
      posturl: "",
      postData: {},
      isSolid: false,
      isMouseDown: false,
      isSuccess: true,
      isError: false,
      moustStart: 0,
      moveOffset: 0,
      img: null,
      bgurl: "",
      mark_w: 50,
      mark_h: 50,
      img_w: 240,
      img_h: 150,
      mark_offset: 0,
    };
  },
  mounted() {
    _this = this;
    this.loadImg();
  },
  methods: {
    close() {
      this.$destroy(true);
      this.$el.parentNode.removeChild(this.$el);
    },
    getX(e) {
      if (e.clientX) {
        return e.clientX;
      } else if (e.touches[0]) {
        return e.touches[0].clientX;
      }
      return 0;
    },
    //发送
    async sendData() {
      let sendDataa = this.postData;
      sendDataa.tcode = this.mark_offset;
      const res = await this.$post(this.posturl, sendDataa);
      if (res.code == 400) {
        this.isError = true;
        this.isSuccess = false;
        setTimeout(() => {
          this.loadImg();
        }, 2000);
      } else {
        this.isError = false;
        this.isSuccess = true;
        setTimeout(() => {
          this.close();
          this.handleCall(res);
        }, 1000);
      }
    },
    mouseDown(e) {
      this.isMouseDown = true;
      e.preventDefault();
      this.moustStart = this.getX(e);
      this.isSolid = true;
    },
    mouseMove(e) {
      e.preventDefault();
      if (!this.isMouseDown) {
        return;
      }
      this.moveOffset = this.getX(e) - this.moustStart;
      if (this.moveOffset < 0) {
        this.moveOffset = 0;
      }
      if (this.moveOffset > this.img_w - this.mark_w) {
        this.moveOffset = this.img_w - this.mark_w;
      }
      this.mark_offset =
        (this.moveOffset / (this.img_w - this.mark_w)) *
        (this.img_w - this.mark_w);
      _this.drawBg();
      this.drawMark();
    },
    mouseUp(e) {
      if (!this.isMouseDown) {
        return;
      }
      e.preventDefault();
      this.isMouseDown = false;
      this.sendData();
    },
    drawBg() {
      let cv = _this.$refs.bg.getContext("2d");
      cv.clearRect(0, 0, cv.width, cv.height);
      cv.drawImage(
        _this.img,
        0,
        0,
        _this.img_w,
        _this.img_h,
        0,
        0,
        _this.img_w,
        _this.img_h
      );
    },
    drawMark() {
      let mark = _this.$refs.mark.getContext("2d");
      console.log("mark", this.img_w, this.img_h);
      mark.clearRect(0, 0, this.img_w, this.img_h);
      mark.drawImage(
        _this.img,
        0,
        _this.img_h,
        _this.mark_w,
        _this.img_h,
        _this.mark_offset,
        0,
        _this.mark_w,
        _this.img_h
      );

      var imageData = mark.getImageData(0, 0, this.img_w, this.img_h);
      // 获取画布的像素信息
      // 是一个一维数组，包含以 RGBA 顺序的数据，数据使用  0 至 255（包含）的整数表示
      // 如：图片由两个像素构成，一个像素是白色，一个像素是黑色，那么 data 为
      // [255,255,255,255,0,0,0,255]
      // 这个一维数组可以看成是两个像素中RBGA通道的数组的集合即:
      // [R,G,B,A].concat([R,G,B,A])
      var data = imageData.data;
      //alert(data.length/4);
      var x = this.img_h,
        y = this.img_w;
      for (var j = 0; j < x; j++) {
        var ii = 1,
          k1 = -1;
        for (var k = 0; k < y && k >= 0 && k > k1; ) {
          // 得到 RGBA 通道的值
          var i = (j * y + k) * 4;
          k += ii;
          var r = data[i],
            g = data[i + 1],
            b = data[i + 2];
          // 我们从最下面那张颜色生成器中可以看到在图片的右上角区域，有一小块在
          // 肉眼的观察下基本都是白色的，所以我在这里把 RGB 值都在 245 以上的
          // 的定义为白色
          // 大家也可以自己定义的更精确，或者更宽泛一些
          if (r + g + b < 200) data[i + 3] = 0;
          else {
            var arr_pix = [1, -5];
            var arr_op = [250, 0];
            for (let i = 1; i < arr_pix[0] - arr_pix[1]; i++) {
              var iiii = arr_pix[0] - 1 * i;
              var op = parseInt(
                arr_op[0] -
                  ((arr_op[0] - arr_op[1]) / (arr_pix[0] - arr_pix[1])) * i
              );
              var iii = (j * y + k + iiii * ii) * 4;
              data[iii + 3] = op;
            }
            if (ii == -1) {
              break;
            }
            k1 = k;
            k = y - 1;
            ii = -1;
          }
        }
      }
      mark.putImageData(imageData, 0, 0);
    },
    loadImg() {
      this.isMouseDown = false;
      this.moustStart = 0;
      this.isSolid = false;
      this.moveOffset = 0;
      this.mark_offset = 0;
      this.isError = false;
      this.isSuccess = false;
      this.img = new Image();
      this.img.src = this.loadurl + "?t=" + Date();
      this.img.onload = function () {
        _this.drawBg();
        _this.drawMark();
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.tncode {
  .body {
    background-color: white;
    z-index: 1000000;
    width: 260px;
    height: 260px;
    position: absolute;
    left: 50%;
    top: 50%;
    margin-top: -130px;
    margin-left: -130px;
    border: 1px solid #d1d1d1;
    border-radius: 2px;
    overflow: hidden;
    filter: progid:DXImageTransform.Microsoft.Shadow(color='#969696',Direction=135, Strength=5);
    /* background-color: #ccc; */
    -moz-box-shadow: 2px 2px 5px #969696;
    -webkit-box-shadow: 2px 2px 5px #969696;
    box-shadow: 2px 2px 5px #969696;
    .bg {
      position: absolute;
      left: 10px;
      top: 10px;
    }
    .mark {
      position: absolute;
      left: 10px;
      top: 10px;
    }
    .success-error {
      position: absolute;
      top: 136px;
      left: 10px;
      width: 220px;
      height: 20px;
      color: #fff;
      margin: 0;
      padding: 2px 10px;
      overflow: visible;
      background-position: 0px 0px;
      font-size: 14px;
      opacity: 50;
      filter: alpha(opacity = 50);
      z-index: 10000;
    }
    .error {
      background-color: #de5145;
    }
    .success {
      background-color: #24c628;
    }
    .solid {
      position: absolute;
      top: 160px;
      width: 93.52%;
      height: 0px;
      background-color: white;
      background-size: 100%;
      margin: 5.39% 3.24%;
      padding: 0px 0px 13.67%;
      overflow: visible;
      background-position: 0px 0px;
      background-repeat: no-repeat;
      background-image: url("@/assets/tncode/icon.png");
      .botton {
        background-position: 0px 12.9794%;
        width: 65px;
        height: 65px;
        position: absolute;
        left: 0px;
        top: 0px;
        margin: -4.62% 0 0 -2.31%;
        cursor: pointer;
        background-repeat: no-repeat;
        background-image: url("@/assets/tncode/icon.png");
      }
      .channel {
        background-position: 0px 12.9794%;
        height: 65px;
        position: absolute;
        left: 65px;
        top: 20px;
        margin: -4.62% 0 0 -2.31%;
        cursor: pointer;
        font-size: 14px;
        color: rgb(136, 148, 157);
      }
    }
    .tools {
      position: absolute;
      top: 210px;
      width: 93.52%;
      height: 0px;
      background-color: white;
      background-size: 100%;
      margin: 5.39% 3.24%;
      padding: 5px 0px 13.67%;
      overflow: visible;
      background-position: 0px 0px;
      border-top: 1px solid #eeeeee;
      .close {
        background-position: 0 50%;
        height: 30px;
        width: 30px;
        float: left;
        margin-right: 10px;
        cursor: pointer;
        background-repeat: no-repeat;
        background-image: url("@/assets/tncode/icon.png");
      }
      .refrese {
        background-position: 0 94%;
        height: 30px;
        width: 30px;
        float: left;
        cursor: pointer;
        background-repeat: no-repeat;
        background-image: url("@/assets/tncode/icon.png");
      }
    }
  }
}
</style>
