import Vue from "vue";
import ChaptchaWidget from "./ChaptchaWidget.vue";
let ChaptchaConstructor = Vue.extend(ChaptchaWidget);
export default function Chaptcha(loadurl, sendurl, data, callfunc) {
  let instance = new ChaptchaConstructor({
    data: {
      loadurl: loadurl,
      posturl: sendurl,
      postData: data,
      handleCall: callfunc,
    },
  });
  instance.$mount();
  document.body.appendChild(instance.$el);
}
